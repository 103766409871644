@media (max-Width: 500px) {
    .main {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .main::before {
        background-image: url("../Component Images/PeanutAgro Doodle.jpg");
        background-size: 100% 500px;
        background-repeat: repeat;
        content: '';
        position: absolute;
        inset: 0;
        opacity: 0.2;
    }

    .content {
        z-index: 1;
        padding: 20px;
    }

    .paras {
        display: flex;
        flex-direction: column;
        position: relative;
        border: 5px solid #ADA990;
        border-radius: 10px;
        font-size: 16px;
        padding: 5px;
        font-weight: bold;
    }

    .paras::before {
        content: '';
        position: absolute;
        top: 0; 
        left: 0; 
        right: 0; 
        bottom: 0;
        z-index: -1;
        background-image: inherit;
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    }

    .certificates {
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
        border-radius: 10px;
        box-shadow: 0px 7px 5px 2px #857f7f
    }
}