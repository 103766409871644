.main {
    display: flex;
    flex-direction: column;
    position: relative;
}

.main::before {
    background-image: url('C:/Users/Dell/Desktop/Peanut Agro/peanutagro/src/Peanut Components/Component Images/PeanutAgro Doodle.jpg');
    background-size: 100% 500px;
    background-repeat: repeat;
    content: '';
    position: absolute;
    inset: 0;
    opacity: 0.2;
}

.SingleItemMain {
    z-index: 1;
    padding: 15px;
}

.singleImage {
    height: 100%;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 10px;
}

.mainInfo {
    background-color: #fff704;
    border-radius: 10px;
    border-right: 3px solid #002e23;
    border-left: 3px solid #002e23;
    padding: 3px;
    margin-bottom: 10px;
}
@font-face {
    font-family: myFont;
    src: url('../Fonts/Franchise.ttf');
}
.title {
    display: flex;
    justify-content: center;
    /* font-size: 23px; */
    background-color: aliceblue;
    font-weight: bold;
    margin: 5px;
    border-radius: 8px;
    text-transform: uppercase;
    font-family: myFont;
    font-size: 40px;
}

.description {
    display: flex;
    justify-content: center;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.contactButton {
    display: flex;
    background-color: rgb(14, 127, 233);
    text-decoration: none;
    justify-content: center;
    color: aliceblue;
    font-weight: bolder;
    padding: 8px;
    font-size: 18px;
    border-radius: 7px;
}