@media (max-Width: 500px) {
    .homeContent {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .homeContent::before{
        background-image: url("../Component Images/PeanutAgro Doodle.jpg");
        background-size: 100% 500px;
        background-repeat: repeat;
        content: '';
        position: absolute;
        inset: 0;
        opacity: 0.2;
    }

    .homeIntro {
        display: flex;
        flex-direction: column;
        position: relative;
        z-index: 1;
        font-size: 19px;
        font-weight: bolder; 
        border: 5px solid #ADA990;
        border-radius: 10px;
        margin: 20px;
        padding: 5px;
    }
    /* Requirement in making glass effect and their uses:  */
    /* -position: relative on .homeIntro: By setting the position of .homeIntro to relative, you establish a positioning context for its children. This means any absolutely positioned elements inside .homeIntro will be positioned relative to the boundaries of .homeIntro.
    -position: absolute on ::before pseudo-element: Setting the position of the pseudo-element to absolute removes it from the normal document flow, allowing you to position it precisely within its closest positioned ancestor, which in this case is .homeIntro.
    -top, right, bottom, left: These properties determine the exact positioning of the pseudo-element within its containing block (.homeIntro in this case). By specifying these values, you can control where the pseudo-element appears relative to the edges of its containing block. */

    /* Adding Glass Effect */
    .homeIntro::before {
        content: '';
        position: absolute;
        top: 0; 
        left: 0; 
        right: 0; 
        bottom: 0;
        z-index: -1;
        background-image: inherit;
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    }

    .homeHeading {
        display: flex;
        margin-top: 10px;
        justify-content: center;
        z-index: 1;
        font-weight: bold;
    }

    .updatingPrices {
        display: flex;
        border: 5px solid #ADA990;
        border-radius: 8px;
        /* box-shadow: 0px 10px #ADA990; */
        flex-wrap: wrap;
        margin: 10px;
        align-items: center;
        align-content: center;
        justify-content: center;
        z-index: 1;
        position: relative;
    }

    .updatingPrices::before {
        content: '';
        position: absolute;
        top: 0; 
        left: 0; 
        right: 0; 
        bottom: 0;
        z-index: -1;
        background-image: inherit;
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
    }

    .singlePrice {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 5px solid #ADA990;
        border-radius: 8px;
        width: 45%;
        margin: 6px;
        flex-grow: 2;
    }

    .card {
        margin: 20px;
        margin-bottom: 40px;
        border: 7px solid #ADA990;
        border-radius: 15px;
        box-shadow: 0px 10px #ADA990;
    }

    .card-title {
        display: flex;
        justify-content: center;
        font-weight: bold;
    }

    .card-img-top{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }

    .card-body{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}