@media (max-Width: 500px) {
    .contactContent {
        z-index: 1;
        font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        padding: 20px;
    }

    .formContent {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }

    .textBox {
        border-radius: 5px;
        margin-bottom: 10px;
    }

    .desc {
        font-weight: bold;
        font-size: 20px;
    }

    .submitButton {
        height: 40px;
        background-color: rgb(20, 104, 214);
        color: aliceblue;
        border-radius: 8px;
        border: 0px;
        font-weight: bolder;
        font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
        font-size: 18px;
    }

    /* .info {
        background-color: #FFFFFF;
        padding: 10px;
        border-radius: 10px;
    } */
}