.navbarComp {
    background-color: #936B05 !important;
}
.navbarTitle {
    font-weight: bold;
    color: #D6D006;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.nav-link {
    color: aliceblue;
    font-weight: bold;
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link.show {
    color: aliceblue;
}

